import cn from 'classnames'
import React, { FC, useEffect, useState } from 'react'

import useInterval from '../../hooks/useInterval'
import { timestampFromUnixToWeb } from '../../utils'
import { getTimeAfter, getTimeBefore } from './helpers'

interface TimeAgoProps {
  timestamp: number
  direction?: 'before' | 'after'
  suffix?: string
  className?: string
}

const TimeAgo: FC<TimeAgoProps> = ({ timestamp, suffix, className, direction = 'after' }) => {
  const [content, setContent] = useState('')

  const updateContent = React.useCallback((): void => {
    const dueDate = new Date(timestampFromUnixToWeb(timestamp))
    const text = direction === 'before' ? getTimeBefore(dueDate) : getTimeAfter(dueDate)
    setContent(text)
  }, [timestamp, direction])

  useInterval(updateContent)

  useEffect(() => {
    updateContent()
  }, [updateContent])

  return (
    <div className="time">
      <span className={cn('time__value', className)}>
        {content}
        {suffix ? ` ${suffix}` : ''}
      </span>
    </div>
  )
}
export default TimeAgo
