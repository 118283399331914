const formatTime = (seconds: number): string => {
  const years = Math.floor(seconds / 60 / 60 / 24 / 365)

  if (years > 1) {
    return years + 'yr.'
  }
  const months = Math.floor(seconds / 60 / 60 / 24 / 30)
  if (months > 1) {
    return months + 'mo.'
  }
  const weeks = Math.floor(seconds / 60 / 60 / 24 / 7)
  if (weeks > 1) {
    return weeks + 'wk.'
  }
  const days = Math.floor(seconds / 60 / 60 / 24)
  if (days > 1) {
    return days + 'd.'
  }
  const hours = Math.floor(seconds / 60 / 60)
  if (hours > 1) {
    return hours + 'hr.'
  }
  const mins = Math.floor(seconds / 60)
  if (mins > 1) {
    return mins + 'min.'
  }
  return Math.floor(seconds) + 'sec.'
}

export const getTimeBefore = (date: Date): string => {
  const seconds = Math.floor((date.getTime() - new Date().getTime()) / 1000)
  return formatTime(Math.max(seconds, 0))
}

export const getTimeAfter = (date: Date): string => {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000)
  return formatTime(Math.max(seconds, 0))
}
